
.template-wrapper {
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .template-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-breadcrumb {
            line-height: 40px;
        }
    }
}
